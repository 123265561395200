import { render, staticRenderFns } from "./SectionName.vue?vue&type=template&id=2643a2c8&scoped=true&"
import script from "./SectionName.vue?vue&type=script&lang=js&"
export * from "./SectionName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2643a2c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VDivider,VResponsive})
