<template>
  <NotificationPanel></NotificationPanel>
</template>

<script>
import NotificationPanel from "@/pages/client/notificationsPage/components/NotificationPanel";
export default {
  name: "Notifications",
  components: { NotificationPanel }
};
</script>

<style scoped></style>
