import { render, staticRenderFns } from "./RegulationsTab.vue?vue&type=template&id=61eed944&scoped=true&"
import script from "./RegulationsTab.vue?vue&type=script&lang=js&"
export * from "./RegulationsTab.vue?vue&type=script&lang=js&"
import style0 from "./RegulationsTab.vue?vue&type=style&index=0&id=61eed944&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61eed944",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VCol,VContainer,VRow,VTab,VTabItem,VTabs})
