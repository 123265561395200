<template>
  <RegulationsTab></RegulationsTab>
</template>

<script>
import RegulationsTab from "@/pages/client/regulationsPage/components/RegulationsTab";
export default {
  name: "Regulations",
  components: { RegulationsTab }
};
</script>

<style scoped></style>
