<template>
  <LoginForm></LoginForm>
</template>

<script>
import LoginForm from "@/pages/admin/login/components/LoginForm";
export default {
  name: "Login",
  components: { LoginForm }
};
</script>

<style scoped></style>
